(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/win-div/assets/javascripts/win-div-hvs-mobile.js') >= 0) return;  svs.modules.push('/components/tipsen/win-div/assets/javascripts/win-div-hvs-mobile.js');
"use strict";

const {
  useState,
  useMemo
} = React;
const {
  ReactIcon
} = svs.ui;
const {
  Currency: currency
} = svs.utils.format;
const {
  useCombinedDrawAndPlayerWinDivs
} = svs.components.tipsen.winDiv;
const {
  getCombinedWinDivAlteredForMobile,
  getBestRowText,
  getPlayersCorrectAmountText
} = svs.components.tipsen.winDiv.utils;
const getWinDivListRowClassNames = _ref => {
  let {
    branding,
    playerWins = 0,
    isBestWinDiv,
    winValue,
    isOnlyForCollapsedView,
    isPlayersBestWinDiv,
    isMultiForecastChoiceOnCorrectResult = true
  } = _ref;
  const blockName = 'pg_windiv_grid';
  const classNames = [blockName];
  if (branding && branding.length > 0) {
    classNames.push("".concat(blockName, "--").concat(branding));
  }
  !(isBestWinDiv || isPlayersBestWinDiv) && classNames.push("".concat(blockName, "--hidden-on-collapsed"));
  if (isBestWinDiv) {
    const blockAndElementName = "".concat(blockName, "__best_row");
    classNames.push(blockAndElementName);
    isOnlyForCollapsedView && classNames.push("".concat(blockAndElementName, "--hidden-on-expanded"));
  }
  if (playerWins > 0 && winValue > 0 && !isOnlyForCollapsedView && isMultiForecastChoiceOnCorrectResult) {
    classNames.push("".concat(blockName, "__highlighted"));
    if (branding && branding.length > 0) {
      classNames.push("".concat(blockName, "__highlighted--").concat(branding));
    }
  }
  if (isPlayersBestWinDiv && !isOnlyForCollapsedView) {
    const blockAndElementName = "".concat(blockName, "__my_best_row");
    classNames.push(blockAndElementName);
    !winValue && classNames.push("".concat(blockAndElementName, "--no_win"));
  }
  return classNames;
};
const WinDivHvsMobile = _ref2 => {
  let {
    branding,
    drawWinDivs,
    playerWinDivs,
    drawEventsLength = 13,
    className,
    children,
    hasMultiForecastChoice,
    isGroup,
    hasPlayerWinDivs,
    isMultiForecastChoiceOnCorrectResult
  } = _ref2;
  const [isExpanded, setIsExpanded] = useState(hasMultiForecastChoice);
  const combinedWinDivs = useCombinedDrawAndPlayerWinDivs({
    playerWinDivs,
    drawWinDivs
  });
  const classNamesWinDiv = ['pg_windiv'];
  className && classNamesWinDiv.push(className);
  const classNamesWinDivList = ['pg_windiv_list'];
  classNamesWinDivList.push("".concat(classNamesWinDivList[0], "--").concat(isExpanded ? 'expanded' : 'collapsed'));
  const combinedWinDivsAlteredForMobile = useMemo(() => getCombinedWinDivAlteredForMobile(combinedWinDivs, playerWinDivs), [combinedWinDivs, playerWinDivs]);
  return React.createElement("section", null, React.createElement("div", {
    className: classNamesWinDiv.join(' ')
  }, React.createElement("div", {
    className: classNamesWinDivList.join(' ')
  }, combinedWinDivsAlteredForMobile.map(_ref3 => {
    let {
      winDiv,
      winners,
      winValue,
      playerWins,
      isBestWinDiv,
      isPlayersBestWinDiv,
      isDuplicated: isOnlyForCollapsedView
    } = _ref3;
    const shouldShowTotalCorrects = isExpanded ? typeof winners === 'number' : !isPlayersBestWinDiv;
    return React.createElement("div", {
      className: getWinDivListRowClassNames({
        branding,
        isBestWinDiv,
        isPlayersBestWinDiv,
        playerWins,
        isOnlyForCollapsedView,
        winValue,
        isMultiForecastChoiceOnCorrectResult
      }).join(' '),
      key: "".concat(winDiv, "_").concat(isPlayersBestWinDiv, "_").concat(isOnlyForCollapsedView)
    }, (isBestWinDiv || isPlayersBestWinDiv) && React.createElement("span", {
      className: "pg_windiv_grid__best_row_text"
    }, getBestRowText({
      isBestWinDiv,
      isPlayersBestWinDiv,
      isGroup,
      isOnlyForCollapsedView
    })), React.createElement("span", {
      className: "pg_windiv_grid__correct_amounts"
    }, "".concat(drawEventsLength - (winDiv - 1), " r\xE4tt")), React.createElement("span", {
      className: "pg_windiv_grid__win_commission"
    }, "".concat(currency(winValue) || 0, " kr")), shouldShowTotalCorrects && React.createElement("div", {
      className: "pg_windiv_grid__correct_rows"
    }, "".concat(currency(winners), " st")), !isOnlyForCollapsedView && React.createElement("div", {
      className: "pg_windiv_grid__my_correct_rows"
    }, getPlayersCorrectAmountText(playerWins, isGroup, isExpanded, hasPlayerWinDivs)));
  }))), children, React.createElement("div", {
    className: "expand-container"
  }, React.createElement("button", {
    className: "expand-button",
    onClick: () => {
      setIsExpanded(!isExpanded);
    },
    type: "button"
  }, React.createElement("span", {
    className: "f-400 margin-xs-bottom"
  }, "".concat(isExpanded ? 'Visa mindre' : 'Visa hela utdelningen')), React.createElement(ReactIcon, {
    color: "fc-white",
    icon: isExpanded ? 'menu-up' : 'menu-down',
    size: "200"
  }))));
};
setGlobal('svs.components.tipsen.winDiv.WinDivHvsMobile', WinDivHvsMobile);

 })(window);