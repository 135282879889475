(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/win-div/assets/javascripts/win-div-wrapper.js') >= 0) return;  svs.modules.push('/components/tipsen/win-div/assets/javascripts/win-div-wrapper.js');
"use strict";

const {
  useEffect,
  useState,
  useMemo
} = React;
const {
  useBranding
} = svs.components.tipsen.hooks;
const {
  getDefinitionByProduct
} = svs.components.tipsen.engine.utils;
const {
  WinDiv,
  MultiForecast,
  WinDivHvsMobile
} = svs.components.tipsen.winDiv;
const {
  Otherwise,
  Choose,
  When
} = svs.components.lbUtils.reactConditionals;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  useGetParticipantsByEvent
} = svs.components.tipsen.hooks;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_AM
} = svs.components.lbUtils.useMediaQuery;
const {
  usePrevious
} = svs.components.lbUtils.usePrevious;
const emptyObject = {};
const useGetMultiForecastArrayIndex = (multiForecast, multiForecastChoice) => useMemo(() => {
  if (multiForecast) {
    if (multiForecastChoice) {
      if (multiForecast[multiForecastChoice]) {
        return multiForecast.indexOf(multiForecast[multiForecastChoice]);
      }
      for (let i = 0; i <= multiForecast.length; i++) {
        if (multiForecast[i]) {
          return multiForecast.indexOf(multiForecast[i]);
        }
      }
    }
    for (let i = 0; i <= multiForecast.length; i++) {
      if (multiForecast[i]) {
        return multiForecast.indexOf(multiForecast[i]);
      }
    }
  } else {
    return 0;
  }
}, [multiForecast, multiForecastChoice]);
const WinDivWrapper = _ref => {
  var _getDefinitionByProdu, _drawResults$correctR2, _drawResults$correctR3;
  let {
    productId,
    drawCompetitionId,
    drawNumber,
    drawResults,
    multiForecast,
    drawWinDivs,
    playerWinDivs,
    className,
    isDrawCompleted,
    isGroup,
    children,
    hasSpecialWinDiv = false,
    onMulitiforecastChange = () => {}
  } = _ref;
  const drawId = ''.concat(productId, '_').concat(drawNumber);
  const branding = useBranding(drawId, drawCompetitionId);
  const [productBranding] = branding;
  const isSmall = useMediaQuery(breakpoints.down(BREAKPOINT_AM));
  const eventTypeId = ((_getDefinitionByProdu = getDefinitionByProduct(productId)) === null || _getDefinitionByProdu === void 0 ? void 0 : _getDefinitionByProdu.outcomes.eventTypeId) || undefined;
  const isGoalCount = eventTypeId === EventTypeId.GOAL_COUNT;
  const [oldDrawNumber, setOldDrawNumber] = useState();
  const numberOfEvents = (drawResults || []).length;
  const correctResultEventNumberIndex = multiForecast !== null && multiForecast !== void 0 && multiForecast.eventNumberIndex ? multiForecast.eventNumberIndex : 0;
  const [multiForecastChoice, setMultiForecastChoice] = useState();
  const [homeParticipant, awayParticipant] = useGetParticipantsByEvent(productId, drawNumber, correctResultEventNumberIndex + 1);
  const hasMultiForecast = Boolean(multiForecast === null || multiForecast === void 0 ? void 0 : multiForecast.forecast);
  const previousMultiforecastOutcome = usePrevious(hasMultiForecast ? drawResults[correctResultEventNumberIndex].outcome[0][0] : undefined);
  useEffect(() => {
    if (onMulitiforecastChange) {
      onMulitiforecastChange(multiForecastChoice);
    }
  }, [multiForecastChoice, onMulitiforecastChange]);
  useEffect(() => {
    var _drawResults$correctR;
    const hasMadeAChoise = hasMultiForecast && typeof multiForecastChoice !== 'undefined';
    const isChoiceValid = multiForecast === null || multiForecast === void 0 ? void 0 : multiForecast.forecast[multiForecastChoice];
    const correctOutcome = drawResults ? (_drawResults$correctR = drawResults[correctResultEventNumberIndex]) === null || _drawResults$correctR === void 0 ? void 0 : _drawResults$correctR.outcome[0][0] : undefined;
    const shouldSwitchOutcome = previousMultiforecastOutcome && previousMultiforecastOutcome !== correctOutcome && multiForecastChoice === previousMultiforecastOutcome;
    if (drawResults && hasMultiForecast && (!hasMadeAChoise || !isChoiceValid || oldDrawNumber !== drawNumber || shouldSwitchOutcome)) {
      const outcome = drawResults[correctResultEventNumberIndex].outcome[0][0];
      setOldDrawNumber(drawNumber);
      setMultiForecastChoice(outcome);
    }
  }, [drawResults, multiForecast, multiForecastChoice, correctResultEventNumberIndex, hasMultiForecast, oldDrawNumber, drawNumber, previousMultiforecastOutcome]);
  const multiforecastArrayIndex = useGetMultiForecastArrayIndex(multiForecast === null || multiForecast === void 0 ? void 0 : multiForecast.forecast, multiForecastChoice);
  if (!drawResults || !drawWinDivs) {
    return null;
  }
  const hasMultiForecastChoice = multiForecastChoice !== undefined;
  return React.createElement(React.Fragment, null, hasMultiForecast && React.createElement(MultiForecast, {
    awayParticipant: awayParticipant,
    branding: productBranding,
    changeForecastChoice: setMultiForecastChoice,
    className: className,
    correctResultIndex: drawResults[correctResultEventNumberIndex].outcome[0][0],
    eventTypeId: eventTypeId,
    homeParticipant: homeParticipant,
    multiForecast: multiForecast.forecast,
    selectedIndex: multiForecastChoice
  }), React.createElement(Choose, null, React.createElement(When, {
    condition: isGoalCount && isSmall && hasSpecialWinDiv
  }, React.createElement(WinDivHvsMobile, {
    branding: branding,
    className: className,
    drawEventsLength: numberOfEvents,
    drawWinDivs: hasMultiForecast && hasMultiForecastChoice ? multiForecast.forecast[multiforecastArrayIndex] : drawWinDivs,
    hasMultiForecastChoice: Boolean(multiForecastChoice),
    hasPlayerWinDivs: Boolean(playerWinDivs),
    isDrawCompleted: isDrawCompleted,
    isGroup: isGroup,
    isMultiForecastChoiceOnCorrectResult: drawResults && hasMultiForecastChoice && isGoalCount ? ((_drawResults$correctR2 = drawResults[correctResultEventNumberIndex]) === null || _drawResults$correctR2 === void 0 ? void 0 : _drawResults$correctR2.outcome[0][0]) === multiForecastChoice : true,
    playerWinDivs: playerWinDivs ? playerWinDivs : emptyObject
  }, children)), React.createElement(Otherwise, null, React.createElement(WinDiv, {
    branding: branding.join(' '),
    className: className,
    drawEventsLength: numberOfEvents,
    drawWinDivs: hasMultiForecast && hasMultiForecastChoice ? multiForecast.forecast[multiforecastArrayIndex] : drawWinDivs,
    hasPlayerWinDivs: Boolean(playerWinDivs),
    isDrawCompleted: isDrawCompleted,
    isGoalCount: isGoalCount,
    isGroup: isGroup,
    isMultiForecastChoiceOnCorrectResult: drawResults && hasMultiForecastChoice ? ((_drawResults$correctR3 = drawResults[correctResultEventNumberIndex]) === null || _drawResults$correctR3 === void 0 ? void 0 : _drawResults$correctR3.outcome[0][0]) === multiForecastChoice : true,
    playerWinDivs: playerWinDivs ? playerWinDivs : emptyObject
  }))));
};
setGlobal('svs.components.tipsen.winDiv.WinDivWrapper', WinDivWrapper);

 })(window);