(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/win-div/assets/javascripts/multi-forecast.js') >= 0) return;  svs.modules.push('/components/tipsen/win-div/assets/javascripts/multi-forecast.js');
"use strict";

const {
  useMemo
} = React;
const {
  getOutcomeLabelsForEventType
} = svs.components.sport.tipsenShared;
const {
  RadioButtons
} = svs.lb_ui.radioButtons;
const classNamePrefix = 'pg';
const {
  ParticipantsNames,
  constants: {
    DescriptionSize
  }
} = svs.components.lbUi.participants;
const MultiForecast = _ref => {
  let {
    eventTypeId,
    selectedIndex,
    changeForecastChoice,
    correctResultIndex,
    branding,
    className,
    multiForecast,
    homeParticipant,
    awayParticipant,
    nameSize = DescriptionSize.Medium
  } = _ref;
  const sportType = 1; 
  const outComesForEventTypeId = getOutcomeLabelsForEventType(eventTypeId, sportType);
  const disabledOptions = useMemo(() => {
    const options = [];
    multiForecast.forEach(forecast => options.push(!forecast));
    return options;
  }, [multiForecast]);
  const classNames = ['pg_multiforecast'];
  className && className.length > 0 && classNames.push(className);
  return React.createElement("div", {
    className: classNames.join(' ')
  }, homeParticipant && awayParticipant && React.createElement("div", {
    className: "pg_finalresult"
  }, React.createElement("h4", {
    className: "f-bold f-center margin-top-1 margin-bottom-1"
  }, "Utdelning vid olika utfall"), React.createElement("h4", {
    className: "f-bold f-center margin-bottom-1 pg_teamnames"
  }, React.createElement(ParticipantsNames, {
    awayParticipant: awayParticipant,
    homeParticipant: homeParticipant,
    nameSize: nameSize
  }))), React.createElement(RadioButtons, {
    branding: branding,
    classNamePrefix: classNamePrefix,
    correctIndex: correctResultIndex,
    disabledOptions: disabledOptions,
    handleClick: changeForecastChoice,
    options: outComesForEventTypeId,
    selectedIndex: selectedIndex
  }));
};
setGlobal('svs.components.tipsen.winDiv.MultiForecast', MultiForecast);

 })(window);