(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/win-div/assets/javascripts/use-combined-draw-and-player-win-divs.js') >= 0) return;  svs.modules.push('/components/tipsen/win-div/assets/javascripts/use-combined-draw-and-player-win-divs.js');
"use strict";


const {
  useMemo
} = React;
const useCombinedDrawAndPlayerWinDivs = _ref => {
  let {
    playerWinDivs,
    drawWinDivs = []
  } = _ref;
  return useMemo(() => {
    const combinedRows = drawWinDivs.map(_ref2 => {
      var _playerWinDivs$winDiv;
      let {
        winners,
        winValue,
        winDiv,
        odds
      } = _ref2;
      return {
        winDiv,
        winValue: parseInt(winValue, 10) || 0,
        winners,
        odds,
        playerWins: (playerWinDivs === null || playerWinDivs === void 0 || (_playerWinDivs$winDiv = playerWinDivs[winDiv]) === null || _playerWinDivs$winDiv === void 0 ? void 0 : _playerWinDivs$winDiv.count) || 0
      };
    });

    const bestRowIndex = combinedRows.findIndex(row => row.winners > 0);
    if (bestRowIndex >= 0) {
      combinedRows[bestRowIndex].isBestWinDiv = true;
    }

    const playersBestRowIndex = combinedRows.findIndex(row => row.playerWins > 0);
    if (playersBestRowIndex >= 0) {
      combinedRows[playersBestRowIndex].isPlayersBestWinDiv = true;
    }
    return [...combinedRows];
  }, [drawWinDivs, playerWinDivs]);
};
setGlobal('svs.components.tipsen.winDiv.useCombinedDrawAndPlayerWinDivs', useCombinedDrawAndPlayerWinDivs);

 })(window);