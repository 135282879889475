(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/win-div/assets/javascripts/win-div.js') >= 0) return;  svs.modules.push('/components/tipsen/win-div/assets/javascripts/win-div.js');
"use strict";

const {
  format
} = svs.utils;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM
} = svs.components.lbUtils.useMediaQuery;
const {
  useCombinedDrawAndPlayerWinDivs
} = svs.components.tipsen.winDiv;
const {
  getCorrectAmountHeaderText
} = svs.components.tipsen.winDiv.utils;
const getWinDivRowClassNames = function (branding) {
  let playerWins = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  let isMultiForecastChoiceOnCorrectResult = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  const classNames = ['pg_windiv_grid'];
  if (branding && branding.length > 0) {
    classNames.push("pg_windiv_grid--".concat(branding));
  }
  if (playerWins > 0 && isMultiForecastChoiceOnCorrectResult) {
    classNames.push('pg_windiv_grid__highlighted');
    if (branding && branding.length > 0) {
      classNames.push("pg_windiv_grid__highlighted--".concat(branding));
    }
  }
  return classNames;
};
const WinDiv = _ref => {
  let {
    branding,
    drawWinDivs,
    playerWinDivs,
    drawEventsLength = 13,
    className,
    headerText = '',
    isDrawCompleted,
    isGroup,
    isSportkryss = false,
    hasPlayerWinDivs,
    isMultiForecastChoiceOnCorrectResult,
    isGoalCount
  } = _ref;
  const isExtraSmall = useMediaQuery(breakpoints.down(BREAKPOINT_SM));
  const hasPlayerData = Boolean(playerWinDivs) && hasPlayerWinDivs && !(isGoalCount && !isMultiForecastChoiceOnCorrectResult);
  const combinedWinDivData = useCombinedDrawAndPlayerWinDivs({
    playerWinDivs,
    drawWinDivs
  });
  const winDivClasses = ['pg_windiv'];
  if (className) {
    winDivClasses.push(className);
  }
  return React.createElement("section", null, headerText.length > 0 && React.createElement("h1", {
    className: "h3 f-center margin-bottom-1"
  }, headerText), React.createElement("div", {
    className: winDivClasses.join(' '),
    role: "table"
  }, React.createElement("header", {
    className: "margin-bottom-half",
    role: "rowgroup"
  }, React.createElement("div", {
    className: "pg_windiv_grid pg_windiv_grid--header",
    role: "row"
  }, React.createElement("div", {
    className: "pg_windiv_grid__correct_amounts",
    role: "columnheader"
  }, "Antal r\xE4tt"), React.createElement("div", {
    className: "pg_windiv_grid__win_commission",
    role: "columnheader"
  }, "Utdelning ".concat(isDrawCompleted || isSportkryss ? '' : 'just nu')), React.createElement("div", {
    className: "pg_windiv_grid__correct_rows",
    role: "columnheader"
  }, getCorrectAmountHeaderText({
    isSportkryss,
    hasPlayerData,
    isGroup,
    isExtraSmall
  })))), React.createElement("ol", {
    className: "pg_windiv_rowgroup",
    role: "rowgroup",
    translate: "no"
  }, combinedWinDivData.map(_ref2 => {
    let {
      winDiv,
      winValue,
      odds,
      winners,
      playerWins
    } = _ref2;
    return React.createElement("li", {
      className: getWinDivRowClassNames(branding, playerWins, isMultiForecastChoiceOnCorrectResult).join(' '),
      key: winDiv,
      role: "row"
    }, React.createElement("span", {
      className: "pg_windiv_grid__correct_amounts",
      role: "cell"
    }, isSportkryss ? "".concat(winDiv) : "".concat(drawEventsLength - (winDiv - 1))), !odds && React.createElement("span", {
      className: "pg_windiv_grid__win_commission",
      role: "cell"
    }, "".concat(format.Currency(winValue), " kr")), odds && React.createElement("span", {
      className: "pg_windiv_grid__win_commission",
      role: "cell"
    }, odds === '0,00' ? 'Rullpott' : "Odds: ".concat(format.Odds(odds))), React.createElement("span", {
      className: "pg_windiv_grid__correct_rows",
      role: "cell"
    }, "".concat(format.Currency(winners), " st"), hasPlayerData && " (".concat(playerWins, " st)")));
  }))));
};
setGlobal('svs.components.tipsen.winDiv.WinDiv', WinDiv);

 })(window);